export default [

  // *===============================================---*
  // *--------- STETRANSPORT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/transporteur',
    redirect: '/transporteur/dashboard',
    name: 'transporteur.dashboard',
    component: () => import('@/layouts/espace-transporteur-independant/TransporteurIndependantLayout.vue'),
    meta: {
      layout: 'vertical',
    },
    children: [
      // stetransport
      {
        path: '/transporteur/dashboard',

        component: () => import('@/views/app/espace-transporteur/independant/Home.vue'),
      },
      // disponible
      {
        path: '/transporteur/disponible',

        name: 'transporteur.disponible',

        component: () => import('@/views/app/espace-transporteur/independant/disponible/Disponible.vue'),
      },
      // soumettre un devis
      {
        path: '/transporteur/soumettre-devis',

        name: 'transporteur.soumettre-devis',

        component: () => import('@/views/app/espace-transporteur/independant/disponible/devis/SoumettreDevis.vue'),
      },
      // soumettre un devis
      {
        path: '/transporteur/devis-soumis',

        name: 'transporteur.devis-soumis',

        component: () => import('@/views/app/espace-transporteur/independant/disponible/devis/DevisSoumis.vue'),
      },
      // Devis sous contrat
      {
        path: '/transporteur/sous-contrat',

        name: 'transporteur.sous-contrat',

        component: () => import('@/views/app/espace-transporteur/independant/disponible/devis/SousContrat.vue'),
      },
      // Devis sous cloture
      {
        path: '/transporteur/devis-cloture',

        name: 'transporteur.devis-cloture',

        component: () => import('@/views/app/espace-transporteur/independant/disponible/devis/DevisCloture.vue'),
      },
      // Devis annulé
      {
        path: '/transporteur/devis-annule',

        name: 'transporteur.devis-annule',

        component: () => import('@/views/app/espace-transporteur/independant/disponible/devis/DevisAnnule.vue'),
      },
      // Contrat actif
      {
        path: '/transporteur/contrat-actif',

        name: 'transporteur.contrat-actif',

        component: () => import('@/views/app/espace-transporteur/independant/contrat/ContratActif.vue'),
      },
      // Contrat cloturé
      {
        path: '/transporteur/contrat-cloture',

        name: 'transporteur.contrat-cloture',

        component: () => import('@/views/app/espace-transporteur/independant/contrat/ContratCloture.vue'),
      },
      // Transporteurs
      {
        path: '/transporteur/transporteurs',

        name: 'transporteur.transporteurs',

        component: () => import('@/views/app/espace-transporteur/independant/transporteur/Transporteur.vue'),
      },
      // Moyens de transport
      {
        path: '/transporteur/moyen-de-transport',

        name: 'transporteur.moyen-de-transport',

        component: () => import('@/views/app/espace-transporteur/independant/moyen-de-transport/MoyenTransport.vue'),
      },
      // Disponibilites transporteur
      {
        path: '/transporteur/disponilities',

        name: 'transporteur.disponilities',
        component: () => import('@/views/app/espace-transporteur/independant/transport/Disponibility.vue'),
      },
       // Tracking
       {
        path: '/transporteur/tracking',

        name: 'transporteur.tracking',
        component: () => import('@/views/app/espace-transporteur/independant/tracking/Tracking.vue'),
      },

    ],
  },
]
