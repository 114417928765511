export default [

  // *===============================================---*
  // *--------- AUTH  ---------------------------------------*
  // *===============================================---*
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentification/Login.vue'),
    meta: {
      layout: 'full',
    },

  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/authentification/ForgotPassword.vue'),
    meta: {
      layout: 'full',
    },

  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/authentification/ResetPassword.vue'),
    meta: {
      layout: 'full',

    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/authentification/CreateAccount.vue'),
    meta: {
      layout: 'full',

    },
  },

]
