export default [

  // *===============================================---*
  // *--------- ROUTES ADMINISTRATEURS  ---------------------------------------*
  // *===============================================---*
  {
    path: '/admin',
    redirect: '/admin/dashboard',
    name: 'admin.dashboard',
    component: () => import('@/layouts/espace_admin/AdminLayout.vue'),
    meta: {
      layout: 'horizontal',
    },
    children: [
      // Dashboard
      {
        path: '/admin/dashboard',

        component: () => import('@/views/app/espace_admin/home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              active: true,

            },
          ],
        },
      },
      // Devise
      {
        path: '/admin/devise',
        name: 'admin.devise',
        component: () => import('@/views/app/espace_admin/settings/devise/Devise.vue'),
        meta: {
          pageTitle: 'Devise',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/admin/dashboard',

            },
            {
              text: 'Devise',
              active: true,

            },
          ],
        },
      },
      // Activation de compte
      {
        path: '/admin/activate-account',
        name: 'admin.activateaccount',
        component: () => import('@/views/app/espace_admin/settings/activate-account/ActivateAccount.vue'),
        meta: {
          pageTitle: 'Activation de compte',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/admin/dashboard',

            },
            {
              text: 'Activation de compte',
              active: true,

            },
          ],
        },
      },
      // Document
      {
        path: '/admin/document',
        name: 'admin.document',
        component: () => import('@/views/app/espace_admin/settings/document/Document.vue'),
        meta: {
          pageTitle: 'Document',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/admin/dashboard',

            },
            {
              text: 'Document',
              active: true,

            },
          ],
        },
      },
      // Unité de Mésure
      {
        path: '/admin/unit-type',
        name: 'admin.unittype',
        component: () => import('@/views/app/espace_admin/settings/unite-de-mesure/UniteMesure.vue'),
        meta: {
          pageTitle: 'Unité de Mesure',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/admin/dashboard',

            },
            {
              text: 'Unité de Mesure',
              active: true,

            },
          ],
        },
      },
      // Commentaires
      {
        path: '/admin/comment',
        name: 'admin.comment',
        component: () => import('@/views/app/espace_admin/settings/comment/Comment.vue'),
        meta: {
          pageTitle: 'Commentaire',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/admin/dashboard',

            },
            {
              text: 'Commentaire',
              active: true,

            },
          ],
        },
      },
      // Pays
      {
        path: '/admin/pays',
        name: 'admin.pays',
        component: () => import('@/views/app/espace_admin/settings/donnees-geographiques/pays/Pays.vue'),
        meta: {
          pageTitle: 'Pays',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/admin/dashboard',

            },
            {
              text: 'Pays',
              active: true,

            },
          ],
        },
      },
      // Département
      {
        path: '/admin/pays/:slug/departement',
        name: 'admin.departement',
        component: () => import('@/views/app/espace_admin/settings/donnees-geographiques/departement/Departement.vue'),
        meta: {
          pageTitle: 'Département',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/admin/dashboard',

            },
            {
              text: 'Département',
              active: true,

            },
          ],
        },
      },

      // Ville
      {
        path: '/admin/departement/:slug/ville',
        name: 'admin.ville',
        component: () => import('@/views/app/espace_admin/settings/donnees-geographiques/ville/Ville.vue'),
        meta: {
          pageTitle: 'Ville',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/admin/dashboard',

            },
            {
              text: 'Ville',
              active: true,

            },
          ],
        },
      },

      // Catégorie de camion
      {
        path: '/admin/categorie-camion',
        name: 'admin.transport',
        component: () => import('@/views/app/espace_admin/settings/modele-de-camion/categorie-de-camion/CategorieCamion.vue'),
        meta: {
          pageTitle: 'Catégorie de camion',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/admin/dashboard',

            },
            {
              text: 'Catégorie de camion',
              active: true,

            },
          ],
        },
      },

      // Type de camion
      {
        path: '/admin/:slug/type-camion',
        name: 'admin.transtype',
        component: () => import('@/views/app/espace_admin/settings/modele-de-camion/type-de-camion/TypeCamion.vue'),
        meta: {
          pageTitle: 'Type de camion',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/admin/dashboard',

            },
            {
              text: 'Type de camion',
              active: true,

            },
          ],
        },
      },

      // Catégorie de marchandise
      {
        path: '/admin/categorie-marchandise',
        name: 'admin.marchandise',
        component: () => import('@/views/app/espace_admin/settings/marchandise/categorie-de-marchandise/CategorieMarchandise.vue'),
        meta: {
          pageTitle: 'Catégorie de marchandise',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/admin/dashboard',

            },
            {
              text: 'Catégorie de marchandise',
              active: true,

            },
          ],
        },
      },

      // Type de marchandise
      {
        path: '/admin/:slug/marchandise',
        name: 'admin.marchandisetype',
        component: () => import('@/views/app/espace_admin/settings/marchandise/type-de-marchandise/TypeMarchandise.vue'),
        meta: {
          pageTitle: 'Marchandise',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/admin/dashboard',

            },
            {
              text: 'Marchandise',
              active: true,

            },
          ],
        },
      },

      // Statistique
      {
        path: '/admin/statistiques',
        name: 'admin.statistiques',
        component: () => import('@/views/app/espace_admin/settings/statistique/Statistique.vue'),
        meta: {
          pageTitle: 'Statistique',

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/admin/dashboard',

            },
            {
              text: 'Statistique',
              active: true,

            },
          ],
        },
      },

    ],
  },
]
