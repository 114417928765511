export default [

  // *===============================================---*
  // *--------- STETRANSPORT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/ste-transport',
    redirect: '/ste-transport/dashboard',
    name: 'ste-transport.dashboard',
    component: () => import('@/layouts/espace-ste-transport/SteTransportLayout.vue'),
    meta: {
      layout: 'vertical',
    },
    children: [
      // stetransport
      {
        path: '/ste-transport/dashboard',

        component: () => import('@/views/app/espace-transporteur/ste-transport/Home.vue'),
      },
      // disponible
      {
        path: '/ste-transport/disponible',

        name: 'ste-transport.disponible',

        component: () => import('@/views/app/espace-transporteur/ste-transport/disponible/Disponible.vue'),
      },
      // soumettre un devis
      {
        path: '/ste-transport/soumettre-devis',

        name: 'ste-transport.soumettre-devis',

        component: () => import('@/views/app/espace-transporteur/ste-transport/disponible/devis/SoumettreDevis.vue'),
      },
      // soumettre un devis
      {
        path: '/ste-transport/devis-soumis',

        name: 'ste-transport.devis-soumis',

        component: () => import('@/views/app/espace-transporteur/ste-transport/disponible/devis/DevisSoumis.vue'),
      },
      // Devis sous contrat
      {
        path: '/ste-transport/sous-contrat',

        name: 'ste-transport.sous-contrat',

        component: () => import('@/views/app/espace-transporteur/ste-transport/disponible/devis/SousContrat.vue'),
      },
       // Devis detail
       {
        path: '/ste-transport/detail-devis',

        name: 'ste-transport.detail-devis',

        component: () => import('@/views/app/espace-transporteur/ste-transport/disponible/devis/DetailDevis.vue'),
      },
      // Devis sous cloture
      {
        path: '/ste-transport/devis-cloture',

        name: 'ste-transport.devis-cloture',

        component: () => import('@/views/app/espace-transporteur/ste-transport/disponible/devis/DevisCloture.vue'),
      },
      // Devis annulé
      {
        path: '/ste-transport/devis-annule',

        name: 'ste-transport.devis-annule',

        component: () => import('@/views/app/espace-transporteur/ste-transport/disponible/devis/DevisAnnule.vue'),
      },
      // Contrat actif
      {
        path: '/ste-transport/contrat-actif',

        name: 'ste-transport.contrat-actif',

        component: () => import('@/views/app/espace-transporteur/ste-transport/contrat/ContratActif.vue'),
      },
      // Contrat cloturé
      {
        path: '/ste-transport/contrat-cloture',

        name: 'ste-transport.contrat-cloture',

        component: () => import('@/views/app/espace-transporteur/ste-transport/contrat/ContratCloture.vue'),
      },
      // Contrat detail
      {
        path: '/ste-transport/detail-contrat',

        name: 'ste-transport.detail-contrat',

        component: () => import('@/views/app/espace-transporteur/ste-transport/contrat/DetailContrat.vue'),
      },
      // Transporteurs
      {
        path: '/ste-transport/transporteurs',

        name: 'ste-transport.transporteurs',

        component: () => import('@/views/app/espace-transporteur/ste-transport/transporteur/Transporteur.vue'),
      },
      // Moyens de transport
      {
        path: '/ste-transport/moyen-de-transport',

        name: 'ste-transport.moyen-de-transport',

        component: () => import('@/views/app/espace-transporteur/ste-transport/moyen-de-transport/MoyenTransport.vue'),
      },
      // Ajouter moyens de transport
      {
        path: '/ste-transport/ajouter',

        name: 'ste-transport.ajouter',

        component: () => import('@/views/app/espace-transporteur/ste-transport/moyen-de-transport/AjouterMoyenTransport.vue'),
      },
      // Disponibilites ste transport
      {
        path: '/ste-transport/disponilities',

        name: 'ste-transport.disponilities',
        component: () => import('@/views/app/espace-transporteur/ste-transport/transport/Disponibility.vue'),
      },
      // Ajout Disponibilites ste transport
      {
        path: '/ste-transport/ajout-disponibilite',

        name: 'ste-transport.ajout-disponibilite',
        component: () => import('@/views/app/espace-transporteur/ste-transport/transport/AjouterDisponibilite.vue'),
      },
      // Tracking
      {
        path: '/ste-transport/tracking',

        name: 'ste-transport.tracking',
        component: () => import('@/views/app/espace-transporteur/ste-transport/tracking/Tracking.vue'),
      },

    ],
  },
]
