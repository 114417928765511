export default [

  // *===============================================---*
  // *--------- AFFRETEUR  ---------------------------------------*
  // *===============================================---*
  {
    path: '/affreteur',
    redirect: '/affreteur/dashboard',
    name: 'affreteur',
    component: () => import('@/layouts/espace-affreteur/AffreteurLayout.vue'),
    meta: {
      layout: 'vertical',
    },
    children: [
      // Dashboard
      {
        path: '/affreteur/dashboard',
        name: 'affreteur.dashboard',
        component: () => import('@/views/app/espace-affreteur/Home.vue'),
        meta: {
          pageTitle: 'Shop',
          breadcrumb: [
            {
              text: 'Affreteur',
            },
            {
              text: 'Shop',
              active: true,
            },
          ],
        },
      },
      // Nouvelle Offre
      {
        path: '/affreteur/offres/nouvelle',
        name: 'affreteur.nouvelle.offre',
        component: () => import('@/views/app/espace-affreteur/offers/New.vue'),
      },
      //  Offre en attente
      {
        path: '/affreteur/offres/created',
        name: 'affreteur.created.offre',
        component: () => import('@/views/app/espace-affreteur/offers/Created.vue'),
      },
      //  Offre annulee
      {
        path: '/affreteur/offres/canceled',
        name: 'affreteur.canceled.offre',
        component: () => import('@/views/app/espace-affreteur/offers/Canceled.vue'),
      },
      //  Offre cloturée
      {
        path: '/affreteur/offres/closed',
        name: 'affreteur.closed.offre',
        component: () => import('@/views/app/espace-affreteur/offers/Closed.vue'),
      },
      //  Offre contrat
      {
        path: '/affreteur/offres/contrat',
        name: 'affreteur.contrat.offre',
        component: () => import('@/views/app/espace-affreteur/offers/Contrat.vue'),
      },
      // Contrat actif
      {
        path: '/affreteur/contrat-actif',

        name: 'affreteur.contrat-actif',

        component: () => import('@/views/app/espace-affreteur/contrat/ContratActif.vue'),
      },
      // Contrat cloturé
      {
        path: '/affreteur/contrat-cloture',

        name: 'affreteur.contrat-cloture',

        component: () => import('@/views/app/espace-affreteur/contrat/ContratCloture.vue'),
      },
      // Adresse affreteur
      {
        path: '/affreteur/freight-address/all',

        name: 'affreteur.all.adresse',

        component: () => import('@/views/app/espace-affreteur/address/All.vue'),
      },
      // Ajouter  Adresse affreteur
      {
        path: '/affreteur/freight-address/new',

        name: 'affreteur.new.adresse',

        component: () => import('@/views/app/espace-affreteur/address/New.vue'),
      },
      // Disponibilites affreteur
      {
        path: '/affreteur/disponilities',

        name: 'affreteur.disponilities',
        component: () => import('@/views/app/espace-affreteur/transport/Disponibility.vue'),
      },
      // Tracking
      {
        path: '/affreteur/tracking',

        name: 'affreteur.tracking',
        component: () => import('@/views/app/espace-affreteur/tracking/Tracking.vue'),
      },

    ],
  },
]
