import Vue from 'vue'
import VueRouter from 'vue-router'
import admin from '@/router/routes/admin'
import affreteur from '@/router/routes/affreteur'
import stetransport from '@/router/routes/stetransport'
import transporteur from '@/router/routes/transporteur'
import auth from '@/router/routes/auth'
import site from '@/router/routes/home'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...admin,
    ...affreteur,
    ...stetransport,
    ...transporteur,
    ...auth,
    ...site,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
